import React from 'react';
import { FieldExtensionSDK } from '@contentful/app-sdk';
import { PlainClientAPI } from 'contentful-management';
import { Heading } from '@contentful/f36-components';

import DynamicProductGridItems from './Fields/DynamicProductGrid.items';
import DynamicProductGridReferenceAttributes from './Fields/DynamicProductGrid.referenceAttributes';
import ProductGridSeoReferenceAttributes from './Fields/ProductGrid.seoReferenceAttributes';
import DynamicProductGridSeoReferenceAttributes from './Fields/DynamicProductGrid.seoReferenceAttributes';
import FilterOptionReferenceAttributes from './Fields/FilterOption.referenceAttributes';
import ProductCardFeaturedColors from './Fields/ProductCard.featuredColors';
import PageSections from './Fields/Page.sections';
import VariantSku from './Fields/Variant.sku';
import ProductVariants from './Fields/Product.variants';
import PageUrl from './Fields/Page.url';
import ProductBreadcrumb from './Fields/Product.breadcrumb';
import BreadcrumbResourceReferenceAttributes from './Fields/BreadcrumbResource.referenceAttributes';
import PersonalizedProductContentReferenceAttributes from './Fields/PersonalizedProductContent.referenceAttributes';
import StyleSettingBreakPointGroup from './Fields/StyleSetting.breakPointGroup';

interface FieldProps {
  sdk: FieldExtensionSDK;
  cma: PlainClientAPI;
}
interface IFieldMap {
  [k: string]: (_props: FieldProps) => JSX.Element;
}

/**
 * Mapping of contentTypeId.fieldId to the rendered component
 */
const FieldMap: IFieldMap = {
  'dynamicProductGrid.items': DynamicProductGridItems,
  'dynamicProductGrid.referenceAttributes':
    DynamicProductGridReferenceAttributes,
  'productGrid.seoReferenceAttributes':
    ProductGridSeoReferenceAttributes,
  'dynamicProductGrid.seoReferenceAttributes':
    DynamicProductGridSeoReferenceAttributes,
  'filterOption.referenceAttributes': FilterOptionReferenceAttributes,
  'productCard.featuredColors': ProductCardFeaturedColors,
  'page.sections': PageSections,
  'page.url': PageUrl,
  'product.variants': ProductVariants,
  'variant.sku': VariantSku,
  'product.breadcrumb': ProductBreadcrumb,
  'breadcrumbResource.referenceAttributes':
    BreadcrumbResourceReferenceAttributes,
  'personalizedProductContent.referenceAttributes':
    PersonalizedProductContentReferenceAttributes,
  'headerStyle.headerSize': StyleSettingBreakPointGroup,
  'headerStyle.headerLetterSpacing': StyleSettingBreakPointGroup,
  'headerStyle.headerLineHeight': StyleSettingBreakPointGroup,
  'headerStyle.headerSpaceAfter': StyleSettingBreakPointGroup,
  'headerStyle.eyebrowSize': StyleSettingBreakPointGroup,
  'headerStyle.eyebrowLetterSpacing': StyleSettingBreakPointGroup,
  'headerStyle.eyebrowLineHeight': StyleSettingBreakPointGroup,
  'headerStyle.eyebrowSpaceAfter': StyleSettingBreakPointGroup,
  'bodyStyle.bodySize': StyleSettingBreakPointGroup,
  'bodyStyle.bodyLetterSpacing': StyleSettingBreakPointGroup,
  'bodyStyle.bodyLineHeight': StyleSettingBreakPointGroup,
  'bodyStyle.bodySpaceAfter': StyleSettingBreakPointGroup,
  'cardSection.gutter': StyleSettingBreakPointGroup,
  'contentCard.internalSpace': StyleSettingBreakPointGroup,
  'contentCard.rowSpan': StyleSettingBreakPointGroup,
  'contentCard.columnSpan': StyleSettingBreakPointGroup
};

const Field = ({ sdk, cma }: FieldProps) => {
  const field = `${sdk.contentType.sys.id}.${sdk.field.id}`;
  const Component = FieldMap[field];

  if (Component) {
    return <Component sdk={sdk} cma={cma} />;
  }
  return (
    <Heading>
      ERROR: Product app used in an unsupported field placement:{' '}
      <code>{field}</code>
    </Heading>
  );
};

export default Field;
